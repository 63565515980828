<template>
  <div class="form_class">
    <el-form
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
    >
      <el-form-item label="姓名" prop="name">
        <el-input placeholder="请输入姓名" v-model="form.name" class="input_class">
        </el-input>
      </el-form-item>
      <el-form-item label="电话传真" prop="telephone">
        <el-input placeholder="请输入电话及传真" v-model="form.telephone" class="input_class">
        </el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input placeholder="请输入邮箱" v-model="form.email" class="input_class">
        </el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input
            class="input_class"
            type="textarea"
            placeholder="请输入地址"
            rows="4"
            v-model="form.address"
            maxlength="256"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="primary" class="submitBtn" size="small" @click="handleSubmit" :loading="loading"> 确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "uploadDialog",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dialogType: {
      type: String,
      default: 'add'
    },
  },
  data() {
    return {
      form: {
        name: '',
        telephone: '',
        email: '',
        address: '',
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "change" }],
      },
      loading: false
    }
  },
  mounted() {
    if (this.dialogType == 'edit') {
      this.form = Object.assign({}, this.detail)
    }
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    async handleSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let parmas = {
            user_id: this.USER_INFO.id,
            name: this.form.name,
            address: this.form.address,
            telephone: this.form.telephone,
            email: this.form.email,
          }
          if (this.dialogType === 'edit') {
            parmas.id = this.form.id
          }
          parmas._NOCLEAR = true
          this.loading = true
          let res = await this.$store.dispatch("baseConsole/updateBookingAddress", parmas);

          if (res.success) {
            this.loading = false
            this.$message.success('操作成功')
            this.handleClose()
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.form_class {
  padding: 0 20px 20px;
}
.input_class {
  width: 500px;
}
.upBtn {
  margin-bottom: 20px;
}
.btns {
  margin-top: 10px;
  text-align: right;
}
</style>