<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <el-input
            v-model="queryParams.name_or_address"
            placeholder="请输入名称/地址"
            size="small"
            class="searchInput"
            clearable
        >
        </el-input>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange">查询</el-button>

      </div>
    </div>
    <div class="line"></div>
    <el-button style="margin: 15px" size="small" type="primary" @click="handleAdd">新增地址</el-button>
    <div class="tableBlock">
      <el-table
          :data="tableData"
          style="width: 100%;"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
      >
        <el-table-column label="姓名" :show-overflow-tooltip="true" >
          <div slot-scope="scope">{{ scope.row.name | textFormat }}</div>
        </el-table-column>
        <el-table-column label="地址"  :show-overflow-tooltip="true">
          <div slot-scope="scope">{{ scope.row.address | textFormat }}</div>
        </el-table-column>
        <el-table-column label="电话传真" :show-overflow-tooltip="true">
          <div slot-scope="scope">{{ scope.row.telephone | textFormat }}</div>
        </el-table-column>
        <el-table-column label="邮箱" :show-overflow-tooltip="true">
          <div slot-scope="scope">{{ scope.row.email | textFormat }}</div>
        </el-table-column>
        <el-table-column label="操作" :show-overflow-tooltip="true" width="150">
          <div slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleDel(scope.row)">删除</el-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          @current-change="handleChange"
          :page-size="15"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="地址管理" :visible.sync="dialog" width="700px">
      <uploadDialog :detail="detail" :dialogType="dialogType" v-if="dialog" @handleClose="handleClose"></uploadDialog>
    </el-dialog>
  </div>
</template>
<script>
import uploadDialog from "~bac/components/dealManagement/adress/uploadDialog"
export default {
  name: "index",
  components: { uploadDialog },
  data() {
    return {
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      queryParams: {
        name_or_address: ''
      },
      dialog: false,
      dialogType: 'add',
      detail: {}
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        user_id: this.USER_INFO.id,
        name_or_address: this.queryParams.name_or_address,
        limit: 15,
        start: this.currentPage - 1,
      };
      let result = await this.$store.dispatch(
          "baseConsole/getBookingAddressList",
          params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
    searchChange() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    handleDel(row) {
      this.$confirm("确认删除？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      })
          .then(async () => {
            let parmas = {
              id: row.id,
              deleted: 1,
            }
            let res = await this.$store.dispatch("baseConsole/updateBookingAddress", parmas);
            if (res.success) {
              this.$message.success('操作成功')
              this.getList()
            }
          })
          .catch(() => {});
    },
    handleEdit(row) {
      this.dialogType = 'edit'
      this.detail = Object.assign({}, row)
      this.dialog = true
    },
    handleAdd() {
      this.dialogType = 'add'
      this.dialog = true
    },
    handleClose() {
      this.dialog = false
      this.getList()
    }
  }
}
</script>
<style scoped lang="less">
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc
}
</style>